@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,regular,500,600,700,800,300italic,italic,500italic,600italic,700italic,800italic);

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
}

.custom-loader {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: radial-gradient(farthest-side, #766df4 94%, #0000) top/8px 8px
            no-repeat,
        conic-gradient(#0000 30%, #766df4);
    -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(100% - 8px),
        #000 0
    );
    animation: s3 1s infinite linear;
}

@keyframes s3 {
    100% {
        transform: rotate(1turn);
    }
}
